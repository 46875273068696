import * as React from 'react';
import Footer from './components/footer';
import NavbarApp from './components/navbar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardHeader from '@mui/material/CardHeader';
import logo from './assets/Logo_acs_plus_vector.svg';

import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { loginApiCall } from './serverCommunication/loginCommunication';
import AuthContext from './contexts/authContext';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useContext } from 'react';


const CardComponent = ({
  email, setEmail, password, setPassword, callLogin, loginWarning,
  stayLoggedIn, setStayLoggedIn
}) => {

  const {t} = useTranslation();
  return (
    <React.Fragment>
      <CardHeader
        style={{whiteSpace: 'pre-line'}}
        title={`QUAL-ET
          CONTROL
          CENTER`}
        titleTypographyProps={{color: "primary.main"}}
        align='center'/>
      <CardContent>
        <form onSubmit={callLogin}>
          <TextField
            id="outlined-basic"
            label={t("email")}
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required />
          <TextField
            fullWidth
            required
            value={password}
            onChange={event => setPassword(event.target.value)}
            id="outlined-password-input"
            label={t("Password")}
            type="password"
            size="small"
            autoComplete="current-password"
          />
          <FormControlLabel control={
            <Checkbox checked={stayLoggedIn}
              onChange={(event) => setStayLoggedIn(event.target.checked)} />} label="Stay logged in" />
          {loginWarning ? <p>{t('InvalidUsernamePassword')}</p> : <></>}
          <Button
            size="normal"
            variant='contained'
            fullWidth
            type="submit"
          >Login</Button>
          <Box
            display="flex"
            height={80}
            alignItems="center"
            justifyContent="center"
          >
            <img src={logo} alt="acs plus logo" style={{height: 50}} />
          </Box>
        </form>
      </CardContent>
    </React.Fragment>);
};

function Login() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const [loginWarning, setLoginWarning] = React.useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { login, authed } = useContext(AuthContext);

  const callLogin = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    loginApiCall(email, password, stayLoggedIn, login, setLoginWarning);
    // login().then(() => {
    //   navigate(state?.path || "/home");
    // });
  };

  React.useEffect(() => {
    if (authed) {
      navigate(state?.path || "/home");
    }
  }, [authed, navigate, state]);

  return (
    <>
      <NavbarApp />
      <main>
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '60vh'}}
        >
          <Grid item xs={3}>

            <Card
              sx={{
                minWidth: 200,
                maxWidth: 400, }}
            ><CardComponent email={email}
                setEmail={setEmail} password={password}
                setPassword={setPassword}
                callLogin={callLogin}
                stayLoggedIn={stayLoggedIn}
                setStayLoggedIn={setStayLoggedIn}
                loginWarning={loginWarning} /></Card>
          </Grid>
        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default Login;
