import React, { useEffect, useState, useContext } from 'react';
import Footer from '../../components/footer';
import NavbarApp from '../../components/navbar';
import Card from '@mui/material/Card';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PasswordIcon from '@mui/icons-material/Password';
import Button from '@mui/material/Button';

import { Alert, Grid, FormControlLabel,
  TextField, Dialog, DialogTitle, DialogContent,
  DialogActions, DialogContentText, Checkbox
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../hooks/useFetch';
import AuthContext from '../../contexts/authContext';
import { insertUser, updateUserPassword, updateUserPermission,
  deactivateUser} from '../../serverCommunication/serverCommunication';


function AdminArea() {

  const { t } = useTranslation();
  const { getToken } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [admin, setAdmin] = useState(false);

  const {data: usersDB} = useFetch('/request_users', []);
  const [users, setUser] = useState([]);

  const [selectedUser, setSelectedUser] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [passwordNotification, setPasswordNotification] = useState(false);

  const [openUser, setOpenUser] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  useEffect(() => {
    setUser(usersDB);
  }, [usersDB]);

  const openAddUserDialog = () => {
    setOpenUser(true);
  };
  const closeAddUserDialoag = () => {
    setOpenUser(false);
  };

  const openChangePasswordDialoag = (user) => {
    setSelectedUser(user);
    setOpenPassword(true);
  };
  const closeChangePasswordDialoag = () => {
    setOpenPassword(false);
  };

  const emailUnique = (_email) => {
    const find = users.find(user => user.email === _email);
    if (find === undefined) {
      return true;
    } else {
      return false;
    }
  };

  const addUser = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    if (password === passwordConfirm && emailUnique(email)) {
      const newUser = {email: email, password: password, admin: admin};
      insertUser(getToken(), newUser, setUser);
      setOpenUser(false);
      setEmail('');
      setPassword('');
      setPasswordConfirm('');
      setAdmin(false);
    }
  };

  const changePassword = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    if (newPassword === newPasswordConfirm) {
      const updatedUser = {...selectedUser, password: newPassword, tableData: undefined};
      updateUserPassword(getToken(), updatedUser, setPasswordNotification);
      setOpenPassword(false);
      setNewPassword('');
      setNewPasswordConfirm('');
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPasswordNotification(false);
  };

  const changePermission = (user) => {
    const updatedUser = {...user, admin: !user.admin, tableData: undefined};
    updateUserPermission(getToken(), updatedUser, users, setUser);
  };

  return (
    <>
      <Dialog
        open={openUser}
        onClose={closeAddUserDialoag}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add User"}
        </DialogTitle>
        <form onSubmit={addUser}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Please add following information to create a new user.
            </DialogContentText>
            <TextField
              autoFocus margin="dense" data-testid="mail"
              id="mail" label="Email Address"
              type="email" fullWidth variant="standard"
              required value={email}
              onChange={(event => setEmail(event.target.value))}
            />
            <TextField
              margin="dense" data-testid="password"
              id="password" label="Password"
              type="password" fullWidth variant="standard"
              required value={password}
              onChange={(event => setPassword(event.target.value))}
            />
            <TextField
              margin="dense" data-testid="passwordConfirmation"
              id="passwordConfirmation" label="Confirm Password"
              type="password" fullWidth variant="standard"
              required value={passwordConfirm}
              onChange={(event => setPasswordConfirm(event.target.value))}
            />
            <FormControlLabel
              control={<Checkbox data-testid="admin" checked={admin} onChange={() => setAdmin(!admin)}/>}
              label="Admin" value={admin}
            />
            {(password !== passwordConfirm) ?
              <Alert severity="warning">Passwords do not match</Alert> :
              <></>
            }
            {(emailUnique(email)) ?
              <></>:
              <Alert severity="warning">Email already exists</Alert>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAddUserDialoag}>Cancel</Button>
            <Button type='submit' data-testid="userCreateSubmit"
              disabled={(password !== passwordConfirm || !emailUnique(email)) }
            >Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openPassword}
        onClose={closeChangePasswordDialoag}
        aria-labelledby="alert-password-dialog-title"
        aria-describedby="alert-password-dialog-description"
      >
        <DialogTitle id="alert-password-dialog-title">
          {"Change Password"}
        </DialogTitle>
        <form onSubmit={changePassword}>
          <DialogContent>
            <TextField
              margin="dense" data-testid="newPassword"
              id="newPassword" label="New Password"
              type="password" fullWidth variant="standard"
              required value={newPassword}
              onChange={(event => setNewPassword(event.target.value))}
            />
            <TextField
              margin="dense" data-testid="newPasswordConfirmation"
              id="newPasswordConfirmation" label="Confirm new Password"
              type="password" fullWidth variant="standard"
              required value={newPasswordConfirm}
              onChange={(event => setNewPasswordConfirm(event.target.value))}
            />
            {(newPassword !== newPasswordConfirm) ?
              <Alert severity="warning">Passwords do not match</Alert> :
              <></>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={closeChangePasswordDialoag}>Cancel</Button>
            <Button type='submit' data-testid="passwordChangeSubmit"
              disabled={(password !== passwordConfirm) }>Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
      <NavbarApp />
      <main>
        {(passwordNotification) ?
          <Alert onClose={handleCloseNotification} severity="info">Password was changed successfully!</Alert> :
          <></>
        }
        <Grid item xs={12}>
          <Card>
            <MaterialTable
              sx={{ padding: "100px"}}
              title={t('Admin Area')}
              columns={[
                {title: 'ID', field: 'user_id', type: 'numeric', width: 150},
                {title: 'Email', field: 'email'},
                {title: 'Admin', field: 'admin', type: 'boolean'},
              ]}
              // For missing unique row id
              components={{
                Row: props => <MTableBodyRow id="user-" {...props} />
              }}
              data={users}
              actions={[
                {
                  icon: () => <PersonAddAltIcon color="secondary" />,
                  tooltip: 'Add User',
                  onClick: (event) => openAddUserDialog(),
                  isFreeAction: true,
                  showDetails: true,
                },
                {
                  icon: () => <PasswordIcon color="secondary"/>,
                  tooltip: 'Change Password',
                  onClick: (event, rowData) => openChangePasswordDialoag(rowData),
                },
                {
                  icon: () => <AdminPanelSettingsIcon color="secondary"/>,
                  tooltip: 'Change Permission',
                  onClick: (event, rowData) => changePermission(rowData),
                },
              ]}
              options={{
                filtering: true,
                cellStyle: { padding: '1.3em'},
                headerStyle: { padding: '1.3em'},
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                actionsColumnIndex: -1
              }}
              localization={{
                body: {
                  editRow: {
                    deleteText: 'Do you want to delete this user?',
                    saveTooltip: 'Delete'
                  },
                  deleteTooltip: 'Delete User'
                }
              }}
              editable={{
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      deactivateUser(getToken(), oldData.user_id, setUser);
                      resolve();
                    }, 600);
                  }),
              }}
            />
          </Card>
        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default AdminArea;
