import React, { useEffect, useState, useContext } from 'react';
import Footer from '../../components/footer';
import NavbarApp from '../../components/navbar';

import { Grid, } from '@mui/material';
import AuthContext from '../../contexts/authContext';
import { useLocation } from 'react-router-dom';
import { deleteEntry,
  insertUpdateVariable,
} from '../../serverCommunication/serverCommunication';
// import { useTranslation } from 'react-i18next';
import BreadCrumbComponent from '../../components/breadCrumbComponent';
import { useFetch } from '../../hooks/useFetch';
import { FeedVariableList } from './RenderVariableList';
import { EditVariableCard } from './EditVariableCard';
import ContinueWithoutSavingDialog from './ContinueWithoutSavingDialog';
import VariableConfirmationDialog from './variableConfirmationDialog';


function CreateVariable() {

  const location = useLocation();
  const { getToken } = useContext(AuthContext);
  // const { t } = useTranslation();

  const [feedId, setFeedId] = useState(null);
  const [variables, setVariables] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [changeVariable, setChangeVariable] = useState(null);
  const [variableSaved, setVariableSaved] = useState(true);
  const [materialTableFilters, setMaterialTableFilters] = useState({});

  // Keep track of used object prefixes
  const [objectPrefixes, setObjectPrefixes] = useState([]);

  // Continue without saving dialog
  const [openContinueWithoutSavingDialog, setOpenContinueWithoutSavingDialog] = useState(false);
  const [variableUpdateFunction, setVariableUpdateFunction] = useState();

  // Variable Deactivation Confirmation Dialog
  const [openVariableConfirmationDialog, setOpenVariableConfirmationDialog] = useState(false);
  const [selectedMultiVariateTests, setSelectedMultiVariateTests] = useState([]);

  const pathName = location.pathname;
  const pathFeedId = pathName.split("/")[pathName.split("/").length-1];

  const {data: feedVariables} = useFetch(`/request_feed_variables/${pathFeedId}`);
  const {data: domainTables} = useFetch(`/request_domain_tables`, []);
  const {data: variableMultivariateTests} = useFetch('/request_variable_multivariate_tests');

  useEffect(() => {
    if (feedVariables) {
      setVariables(feedVariables);
    }
  }, [feedVariables]);

  useEffect(() => {
    // Adding empty function for the warning receiver
    // as they are not relevant on this page
    setFeedId(parseInt(pathFeedId));
  }, [getToken, pathFeedId]);

  useEffect(() => {
    const prefixes = [];
    variables.forEach((variable) => {
      let passedValue = variable.variable_name;
      while (passedValue.includes('{') || passedValue.includes('[')) {
        const curlyIndex = passedValue.indexOf('{');
        const bracketIndex = passedValue.indexOf('[');

        let firstElement;
        if (curlyIndex !== -1 && (bracketIndex === -1 || curlyIndex < bracketIndex)) {
          firstElement = [passedValue.slice(0, curlyIndex + 1), passedValue.slice(curlyIndex + 1)];
        } else {
          firstElement = [passedValue.slice(0, bracketIndex + 1), passedValue.slice(bracketIndex + 1)];
        }
        passedValue = firstElement[1];

        prefixes.push(firstElement[0]);
      }
    });
    setObjectPrefixes([...new Set(prefixes)].sort());

  }, [variables]);

  const closeEditView = () => {
    setChangeVariable(null);
    setSelectedIndex(-1);
    setVariableSaved(true);
  };

  /**
   * Creates a copy of given variable
   * @param {object} variable
   */
  const copyVariable = (variable) => {

    // Create a copy of the variable
    const variableCopy = {...variable};

    // Delete the id to create a new variable
    delete variableCopy.variable_id;

    // Change the name to show that it is a copy
    variableCopy.variable_name += '_COPY';
    insertUpdateVariable(getToken(), variableCopy,
      variables, setVariables);
  };

  const storeVariable = (variable) => {
    insertUpdateVariable(getToken(), variable,
      variables, setVariables);
    closeEditView();
  };

  const checkDeleteVariable = (variable_id) => {
    const multiVariateTests = variableMultivariateTests
      .filter(element => element.variable_id === variable_id)
      .map(element => element.multivariate_test_name);
    if (multiVariateTests.length > 0) {
      setSelectedMultiVariateTests(multiVariateTests);
      setOpenVariableConfirmationDialog(true);
    } else {
      callDeleteVariable(variable_id);
    }
  };

  const callDeleteVariable = (variable_id) => {
    deleteEntry(
      getToken(),
      `/deactivate_variable/${variable_id}`,
      () => setVariables(a => a.filter(e => e.variable_id !== variable_id))
    );
    closeEditView();
  };

  const newVariable = () => {
    closeEditView();
    setChangeVariable(
      { feed_id: feedId,
        semantical_type_id: '',
        technical_type_id: '',
        variable_description: '',
        variable_name: '',
        missings_allowed: false,
        type_conversion: false,
        variable_required: false,
        decimal_separator: '.',
        quality_tests: []});
  };

  const selectVariable = (row, index) => {
    // Reset the view
    closeEditView();
    setSelectedIndex(index);
    // Reload the view
    setChangeVariable(row);
  };

  const handleVariableChange = (mode, row, index) => {
    if (variableSaved) {
      if (mode === 'newVariable') {
        newVariable();
      } else {
        selectVariable(row, index);
      }
    } else {
      if (mode === 'newVariable') {
        setVariableUpdateFunction(() => () => newVariable());
      } else {
        setVariableUpdateFunction(() => () => selectVariable(row, index));
      }
      setOpenContinueWithoutSavingDialog(true);
    }
  };

  const handleFilterChange = (filters) => {
    const tempFilters = {};

    filters.forEach((filter, index) => {
      tempFilters[filter.column.field] = filter.value;
    });

    setMaterialTableFilters(tempFilters);
  };

  return (
    <>
      <NavbarApp />
      <main>
        <BreadCrumbComponent
          path={[{route: 'Dashboard', link: '/'}, {route: 'Feeds', link: '/feeds'},
            {route: 'Edit Feed', link: `/editFeed/${feedId}`}]}
          currentRoute={'Feed Variables'} />

        <ContinueWithoutSavingDialog
          open={openContinueWithoutSavingDialog}
          setOpen={setOpenContinueWithoutSavingDialog}
          executeFunction={variableUpdateFunction}
        />

        <VariableConfirmationDialog
          open={openVariableConfirmationDialog}
          setOpen={setOpenVariableConfirmationDialog}
          executeFunction={callDeleteVariable}
          variable={changeVariable}
          multiVariateTests={selectedMultiVariateTests}
        />

        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item xs={12} md={4}>
            <FeedVariableList
              variables={variables}
              handleVariableChange={handleVariableChange}
              selectVariable={selectVariable}
              newVariable={newVariable}
              feedId={feedId}
              selectedIndex={selectedIndex}
              copyVariable={copyVariable}
              variableSaved={variableSaved}
              handleFilterChange={handleFilterChange}
              materialTableFilters={materialTableFilters}
            />
          </Grid>

          {changeVariable !== null ?
            <Grid item xs={12} md={8}>
              <EditVariableCard
                currentVariable={changeVariable}
                domainTables={domainTables}
                suggestedTags={objectPrefixes}
                storeVariable={storeVariable}
                checkDeleteVariable={checkDeleteVariable}
                closeEditView={closeEditView}
                variableSaved={variableSaved}
                setVariableSaved={setVariableSaved}
                otherVariables={variables.filter(a => a.variable_name !== changeVariable.variable_name)}
              />

            </Grid>: <></>}
        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default CreateVariable;
