import { Button, Card, CardActions,
  CardContent, CardHeader, Divider, List,
  TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { RenderTest } from "./RenderTest";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from "react-i18next";
import useMultivariateTest from "../../hooks/useMultivariateTestHook";
import DomainContext from "../../contexts/domainContext";
import { useEffect } from "react";

const MultivariateTestsComponent = ({
  selectedQualityTests,
  variables,
  updateTest,
  updateTestParameter,
  removeTest,
  updateVariable,
  type,
  domainTables,
}) => {

  const {qualityTests} = useContext(DomainContext);

  return (
    <List>
      {selectedQualityTests.length > 0 ?
        selectedQualityTests.map((test, index) => {
          return (
            <RenderTest
              test={test}
              key={`${type}-test-${index}`}
              variables={variables}
              qualityTests={qualityTests}
              testParameterValues={test.test_parameter}
              updateMultivariateTests={updateTest}
              updateMultivariateTestParameters={updateTestParameter}
              removeTest={removeTest}
              updateVariable={updateVariable}
              type={type}
              index={index}
              domainTables={domainTables}
            />
          );

        }) : <Typography variant="h6">Please select at least one test.</Typography>

      }
    </List>
  );
};


const ManageMultivariateTest = ({
  selectedIndex, variables,
  multivariateTests,
  updateTest,
  updateTestParameter,
  addTest,
  removeTest,
  multivariateTestDescriptions,
  updateMultivariateTestDescription,
  updateVariable, domainTables
}) => {

  const { t } = useTranslation();
  if (selectedIndex === -1) {
    return (<></>);
  }

  return (
    <>
      <Typography mb={2} variant="h6">Connect conditions for two or more variables</Typography>
      <TextField
        data-testid='multivariate-test-name'
        sx={{mr: 2, mb: 2}}
        required
        id="Multivariate-test-name"
        label="Multivariate Test Name"
        variant="outlined"
        value={multivariateTestDescriptions.multivariate_test_name}
        onChange={(event => updateMultivariateTestDescription('multivariate_test_name', event.target.value))}
        fullWidth
      />
      <TextField
        data-testid='multivariate-test-description'
        sx={{mr: 2, mb: 2}}
        id={'mv-description'}
        label='Description'
        multiline
        value={multivariateTestDescriptions.multivariate_test_description}
        onChange={(event => updateMultivariateTestDescription('multivariate_test_description', event.target.value))}
        fullWidth
        required
      />
      <Divider light />
      <Typography mb={2} mt={2} variant="h6">Conditions</Typography>
      <MultivariateTestsComponent
        selectedQualityTests={multivariateTests
          .filter(qTest => qTest.test_type === 'condition_test')}
        variables={variables}
        updateTest={updateTest}
        updateTestParameter={updateTestParameter}
        removeTest={removeTest}
        updateVariable={updateVariable}
        type={'condition'}
        domainTables={domainTables}
      />
      <Button
        sx={{my: 2}} variant='outlined'
        onClick={() => addTest('condition_test')}
        startIcon={<AddCircleOutlineIcon />}>
          Add Condition
      </Button>


      <Divider light />
      <Typography mb={2} mt={2} variant="h6">{t('Effect')}</Typography>
      <MultivariateTestsComponent
        selectedQualityTests={multivariateTests
          .filter(qTest => qTest.test_type === 'effect_test')}
        variables={variables}
        updateTest={updateTest}
        updateTestParameter={updateTestParameter}
        removeTest={removeTest}
        updateVariable={updateVariable}
        type={'effect'}
        domainTables={domainTables}
      />
      <Button
        sx={{my: 2}}
        variant='outlined'
        onClick={() => addTest('effect_test')}
        startIcon={<AddCircleOutlineIcon />}>Add Effect</Button>

    </>
  );
};

export const ManageMultivariateTestCard = ({
  selectedMultivariateTest, storeMultivariateTest,
  closeEditView, deactivateMultivariateTest,
  variables, selectedIndex, domainTables
}) => {

  const { t } = useTranslation();

  const { multivariate_test_description,
    multivariate_test_instance_id,
    multivariate_test_name} = selectedMultivariateTest;

  const { multivariateTests,
    setMultivariateTests,
    addTest,
    updateTest, updateTestParameter,
    removeTest,
    multivariateTestDescriptions,
    setMultivariateDescriptions,
    updateMultivariateTestDescription,
    updateVariable,
    returnMultivariateTests,
  } = useMultivariateTest(
    { multivariate_test_description, multivariate_test_instance_id, multivariate_test_name},
    selectedMultivariateTest.quality_tests
  );

  const handleSave = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    storeMultivariateTest(returnMultivariateTests());
  };

  useEffect(() => {
    setMultivariateTests(selectedMultivariateTest.quality_tests);
    setMultivariateDescriptions({multivariate_test_description, multivariate_test_instance_id, multivariate_test_name});

  }, [selectedMultivariateTest, setMultivariateTests, setMultivariateDescriptions,
    multivariate_test_description, multivariate_test_instance_id, multivariate_test_name
  ]);

  return (
    <Card
      key={`multivariate-test-${selectedIndex}`}
      title='Multivariate Test'
      data-testid={`multivariate-test-${selectedIndex}`}
    >
      <CardHeader title='Multivariate Test'/>
      <form onSubmit={handleSave}>
        <CardContent>
          <ManageMultivariateTest
            selectedIndex={selectedIndex}
            variables={variables}
            multivariateTests={multivariateTests}
            multivariateTestDescriptions={multivariateTestDescriptions}
            updateMultivariateTestDescription={updateMultivariateTestDescription}
            updateTest={updateTest}
            updateTestParameter={updateTestParameter}
            addTest={addTest}
            removeTest={removeTest}
            updateVariable={updateVariable}
            domainTables={domainTables}
          />
        </CardContent>
        <CardActions>
          <Button
            size="small" color='secondary'
            onClick={() => closeEditView()}>{t('Cancel')}</Button>
          {selectedMultivariateTest?.multivariate_test_instance_id > 0 ?
            <Button
              size="small" color='error'
              onClick={() => {
                deactivateMultivariateTest(selectedMultivariateTest.multivariate_test_instance_id);
              }}
            >{t('Deactivate')}</Button>
            : <></>
          }
          <Button
            disabled={multivariateTests
              .filter(qTest => qTest.test_type === 'effect_test').length === 0 ||
              multivariateTests
                .filter(qTest => qTest.test_type === 'condition_test').length === 0 }
            size="small"
            type='submit'
          >{t('Save')}</Button>
        </CardActions>
      </form>
    </Card>
  );
};
