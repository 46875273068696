import { useEffect, useState, useContext } from "react";
import { checkAuthenticated, createHeader } from "../serverCommunication/communicationHelper";
import AuthContext from '../contexts/authContext';

/**
 *
 * @param {*} url Server url that will be called
 * @param {*} defaultValue value for the data attribute until it is set.
 * @returns
 */
export const useFetch = (url, defaultValue) => {

  const {getToken} = useContext(AuthContext);

  const [data, setData] = useState(defaultValue);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(url, {
      method: 'POST',
      headers: createHeader(getToken()),
    })
      .then(response => response.json())
      .then((result) => {
        checkAuthenticated(result);
        setData(result.data);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [url, getToken]);

  return { data, error, loading };
};
