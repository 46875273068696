import { Button, Card, CardContent, CardHeader, List, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useContext, useEffect } from "react";
import DomainContext from "../contexts/domainContext";
import { RenderFeedTest } from "./RenderFeedTest";
import { useTranslation } from "react-i18next";
import useFeedTests from "../hooks/useFeedTestHook";


const RenderTestElementList = ({
  feedAttributes,
  feedTests,
  updateFeedTests,
  updateMultivariateTestParameters,
  removeTest
}) => {

  const {qualityTests} = useContext(DomainContext);

  return (
    <List>
      {feedAttributes && feedTests.map((test, index) => (
        <RenderFeedTest
          test={test}
          key={`feed-test-${index}`}
          feedAttributes={feedAttributes}
          qualityTests={qualityTests}
          updateMultivariateTests={updateFeedTests}
          updateMultivariateTestParameters={updateMultivariateTestParameters}
          removeTest={removeTest}
          index={index}
        />
      ))}

    </List>
  );
};


export const FeedTestsComponent = ({
  feedTestAttributes, receivedFeedTests, setFeedTestValues,
  existingFeed}
) => {

  const { t } = useTranslation();

  const { feedTests, addTest, removeTest,
    updateTest, updateTestParameter, setDatabaseFeedTests,
    setDefaultFeedTest, updated } = useFeedTests([]);

  useEffect(() => {
    if (receivedFeedTests && !updated) {
      setDatabaseFeedTests(receivedFeedTests);
    } else if (existingFeed === false && !updated) {
      // Set the default feed tests
      setDefaultFeedTest();
    }

  }, [receivedFeedTests, setDatabaseFeedTests, existingFeed, setDefaultFeedTest, updated]);

  useEffect(() => {
    setFeedTestValues(feedTests);
  }, [feedTests, setFeedTestValues]);

  return (
    <Card>
      <CardHeader
        title={
          t('Feed Acceptance Criteria')
        }/>
      <CardContent>
        <Typography variant="p">
          Define the rules based on which it is decided whether a feed execution is successful.
          If there are no criteria the feed is always successful.
        </Typography>
        <RenderTestElementList
          feedAttributes={feedTestAttributes}
          feedTests={feedTests}
          updateFeedTests={updateTest}
          updateMultivariateTestParameters={updateTestParameter}
          removeTest={removeTest}

        />
        <Button
          sx={{my: 2}} variant='outlined'
          onClick={() => addTest()}
          startIcon={<AddCircleOutlineIcon />}>
            Add Feed Test
        </Button>
      </CardContent>
    </Card>
  );
};
