import * as React from 'react';
import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import logo_white from '../assets/logo_acs-plus_white.svg';


export default function Footer() {
  return (
    <footer
      data-testid="footer-id">
      <Box
        sx={{
          color: 'white',
          backgroundColor: 'primary.main',
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <div>
          <img src={logo_white} alt="acs plus Logo" style={{height: 40}} />
        </div>
        <div data-testid="copyright">
          &copy; {new Date().getFullYear()} <b>acs plus.</b> All Rights Reserved.
        </div>
        <div>
          {/* <Box sx={{
            float: {
              xs: 'none',
              sm: 'right'
            },
          }}>
            <Link href="https://acs-plus.de/de/impressum.html"
              underline="none"
              sx={{marginRight: 2}}
              target="_blank"
              rel="noopener"
              color="white">
              {'Impressum'}
            </Link>
            <Link href="https://acs-plus.de/de/datenschutz.html"
              underline="none" target="_blank" rel="noopener"
              color="white">
              {'Datenschutz'}
            </Link>
          </Box> */}
        </div>
      </Box>
    </footer>

  );

}
