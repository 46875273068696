import React, {createContext, useState, useEffect} from "react";
import decode from 'jwt-decode';

const AuthContext = createContext();

export function AuthProvider({ children }) {

  const [authed, setAuthed] = useState(false);
  const loadToken = () => localStorage.getItem('qual-et-token');
  const isTokenExpired = token => decode(token).exp < Date.now() / 1000;

  useEffect(() => {
    const token = loadToken();
    // console.log("Token", decode(token));
    setAuthed(!!token && !isTokenExpired(token));
  }, [authed]);

  const login = (token) => {
    return new Promise((res) => {
      if (!token) {
        return;
      }
      localStorage.setItem('qual-et-token', token);
      setAuthed(true);
      res();
    });
  };
  const logout = () => {
    return new Promise((res) => {
      localStorage.removeItem('qual-et-token');
      setAuthed(false);
      res();
    });
  };
  const getToken = () => { return loadToken(); };
  const getDecodedToken = () => { return decode(getToken()); };
  const getUserDetails = () => { return decode(getToken()).sub; };
  const getUserId = () => { return decode(getToken()).sub.user_id; };
  const isAdmin = () => { return decode(getToken()).sub.admin; };
  const isAuthed = () => { return !isTokenExpired(getToken()); };


  return (
    <AuthContext.Provider
      value={{authed,
        login,
        logout,
        getDecodedToken,
        getUserDetails,
        getToken,
        getUserId,
        isAdmin,
        isAuthed}}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
