import React, { useEffect, useState, useContext } from 'react';
import Footer from '../../components/footer';
import NavbarApp from '../../components/navbar';
import { useTranslation } from "react-i18next";
import BreadCrumbComponent from '../../components/breadCrumbComponent';
import { useLocation } from 'react-router-dom';
import { Card, Divider, Grid, List, ListItemButton,
  ListItemIcon,
  ListItemText} from '@mui/material';
import AuthContext from '../../contexts/authContext';
import { deactivateMultivariateTest, insertUpdateMultivariateTest,
  requestMultivariateTests } from '../../serverCommunication/multivariateTestCommunication';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ManageMultivariateTestCard } from './ManageMultivariateTestComponent';
import { useFetch } from '../../hooks/useFetch';
import MaterialTable from '@material-table/core';
import { useTheme } from '@emotion/react';


const MultiVariateTestList = (
  {multivariateTests, selectedIndex, setSelectedIndex, setSelectedMultivariateTest,
    newMultivariateTest, copyMVTest, setMaterialTableFilters, materialTableFilters
  }) => {
  const theme = useTheme();

  return (
    <List component="nav" aria-label="multi variate tests">
      <ListItemButton
        key={`button-new-mv-test`}
        selected={selectedIndex === 0}
        onClick={() => {
          newMultivariateTest();
        }}
      >
        <ListItemIcon>
          <AddCircleOutlineIcon />
        </ListItemIcon>
        <ListItemText primary={'New Multivariate Test'}
        />
      </ListItemButton>
      <Divider />
      <MaterialTable
        title={""}
        columns={[
          {title: 'Multivariate Test',
            field: 'multivariate_test_name',
            defaultFilter: materialTableFilters.length > 0 ? materialTableFilters[0].value : null
          },
        ]}
        data={multivariateTests}
        actions={[
          {
            icon: () => <ContentCopyIcon />,
            tooltip: 'Copy',
            onClick: (event, data) => copyMVTest(data)
          },
        ]}
        onRowClick={(event, rowData) => {
          setSelectedIndex(rowData.tableData.index + 1);
          setSelectedMultivariateTest(rowData);
        }}
        onFilterChange={(filters) => {
          setMaterialTableFilters(filters);
        }}
        options={{
          filtering: true,
          cellStyle: { padding: '0.3em'},
          headerStyle: { padding: '0.3em'},
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          actionsColumnIndex: -1,
          rowStyle: (rowData) => ({
            backgroundColor: selectedIndex -1 === rowData.tableData.index ?
              theme.palette.action.selected : theme.palette.background.paper,
          }),
        }}
      />
    </List>
  );
};


function HandleMultivariateTests() {
  const { t } = useTranslation();
  const location = useLocation();

  const [feedId, setFeedId] = useState(null);
  const [multivariateTests, setMultivariateTests] = useState([]);
  const { getToken } = useContext(AuthContext);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  // For the selected mv test instance
  const [selectedMultivariateTest, setSelectedMultivariateTest] = useState(undefined);
  const [materialTableFilters, setMaterialTableFilters] = useState([]);

  const pathName = location.pathname;
  const pathFeedId = pathName.split("/")[pathName.split("/").length-1];

  // Getting the feed variables
  const {data: variables} = useFetch(`/request_feed_variables/${pathFeedId}`);
  const {data: domainTables} = useFetch(`/request_domain_tables`, []);

  useEffect(() => {
    setFeedId(pathFeedId);
    requestMultivariateTests(getToken(), pathFeedId, setMultivariateTests);
  }, [pathFeedId, getToken]);

  const closeEditView = () => {
    setSelectedIndex(-1);
    setSelectedMultivariateTest(undefined);
  };

  const storeMultivariateTest = (multivariateTest) => {

    insertUpdateMultivariateTest(
      getToken(), multivariateTest,
      multivariateTests, setMultivariateTests);
    closeEditView();
  };

  const newMultivariateTest = () => {
    closeEditView();
    const newMvTest = {
      multivariate_test_name: '',
      multivariate_test_description: '',
      multivariate_test_instance_id: -1,
      quality_tests: []
    };
    setSelectedMultivariateTest(newMvTest);
    setSelectedIndex(0);
  };

  const copyMVTest = (multivariateTest) => {

    const tempMVTest = {...multivariateTest};

    // Set the id of the multivariate test to -1
    tempMVTest.multivariate_test_instance_id = -1;
    // Add the copy to the name
    tempMVTest.multivariate_test_name = tempMVTest.multivariate_test_name + '_COPY';
    insertUpdateMultivariateTest(
      getToken(), tempMVTest,
      multivariateTests, setMultivariateTests);
  };

  const deactivateMultivariateTestCaller = (multivariate_test_instance_id) => {
    closeEditView();
    deactivateMultivariateTest(getToken(),
      selectedMultivariateTest.multivariate_test_instance_id,
      setMultivariateTests);
  };

  return (
    <>
      <NavbarApp />
      <main>
        <BreadCrumbComponent
          path={[{route: 'Dashboard', link: '/'}, {route: 'Feeds', link: '/feeds'},
            {route: 'Edit Feed', link: `/editFeed/${feedId}`},
            {route: 'Feed Variables', link: `/createVariable/${feedId}`}
          ]}
          currentRoute={t('Multivariate Tests')} />
        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item xs={12} md={4}>
            <Card>
              <MultiVariateTestList
                multivariateTests={multivariateTests}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                setSelectedMultivariateTest={setSelectedMultivariateTest}
                newMultivariateTest={newMultivariateTest}
                copyMVTest={copyMVTest}
                setMaterialTableFilters={setMaterialTableFilters}
                materialTableFilters={materialTableFilters}
              />
            </Card>
          </Grid>
          {selectedIndex !== -1 ?
            <Grid item xs={12} md={8}>
              <ManageMultivariateTestCard
                selectedMultivariateTest={selectedMultivariateTest}
                storeMultivariateTest={storeMultivariateTest}
                closeEditView={closeEditView}
                deactivateMultivariateTest={deactivateMultivariateTestCaller}
                variables={variables}
                selectedIndex={selectedIndex}
                domainTables={domainTables}
              />


            </Grid>: <></>}
        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default HandleMultivariateTests;
