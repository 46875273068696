import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary,
  Box, Card, CardHeader, Grid, Link, Tab,
  Tabs, Typography } from '@mui/material';
import Footer from '../../components/footer';
import NavbarApp from '../../components/navbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Documentation() {
  const [value, setValue] = React.useState(0);
  const currentUrl = window.location.href.split('/documentation')[0];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <NavbarApp />
      <main>
        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item xs={12} md={9}>
            <Card>
              <CardHeader title={'Api Documentation'} />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Swagger-Documentation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" gutterBottom>
                  An overview of endpoints and how to call them and the expected outpunt
                  can be found in the
                    <span> </span>
                    <Link href={`${currentUrl}/swagger-ui`} underline="none">
                    Swagger documentation
                    </Link>
                  .
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Authentification & Message Example</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" gutterBottom>
                  Every feed has a unique complex token,
                  which needs to be sent with the feed to authenticate that the message sent for this feed is valid.
                  The strings inside the angle brackets are placeholder strings. The reference label is optional.
                  </Typography>
                  <div className="markdown">
                    <pre><code className="language-js"><span className="token keyword">
                      {
                        // eslint-disable-next-line max-len
                        `{\n "feed_id": <feed_id>, \n "token": "<token>",\n "reference_label": "<label>",\n "data": [<data>]\n}
                    `}</span>
                    </code></pre>
                  </div>

                  <Typography variant='h6'>Data Example</Typography>
                  <Typography variant="body2" gutterBottom>
                  The data consists of json dictionary entries.
                  The keys of the dictionary are the variables defined for the feed.
                  The attributes <code>price</code> and <code>product_name</code> are the variables.
                  The variables for each element of a feed need to be the same.
                  </Typography>
                  <code className="language-js"><span className="token keyword" style={{whiteSpace: 'pre-wrap'}}>
                  [
                    {`
  {"price": 2.32, "product_name": "Olive Oil"},
  {"price": 1.23, "product_name": "Vinegar"} \n]
                  `}</span>

                  </code>

                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Handling of nested data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontWeight: 'bold' }}>Simple lists:</Typography>
                  <Typography variant="body2" gutterBottom>
                    <code className="language-js"><span className="token keyword" style={{whiteSpace: 'pre-wrap'}}>
                  [
                      {`
  {"location": "Shop1", "product": ["Olive Oil", "Sunflower Oil", "Seed Oil"] },
  {"location": "Shop2", "product": ["Motor Oil"] } \n]
                  `}</span>
                    </code>
                    <br/>
                    The variable product is treated as a normal String variable as all the values are strings.
                    Univariate quality checks are successful
                    if <strong>all</strong> values are compliant with the quality rules.
                    For multivariate tests, at least <strong>one</strong> value needs
                    to comply.
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>Nested objects:</Typography>
                  <Typography variant="body2" gutterBottom>
                    <code className="language-js"><span className="token keyword" style={{whiteSpace: 'pre-wrap'}}>
                  [
                      {`
  {"location": "Shop1", "product": {"name": "Olive Oil", "price": 12.12, "country": "Italy"} },
  {"location": "Shop2", "product": {"name": "Olive Oil", "price": 15.12, "country": "Greece"} } \n]
                  `}</span>
                    </code>
                    <br/>
                    {`Need to be entered as product{name, product{price, product{country`}
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>List of nested objects:</Typography>
                  <Typography variant="body2" gutterBottom>
                    <code className="language-js"><span className="token keyword" style={{whiteSpace: 'pre-wrap'}}>
                  [
                      {`
  {"location": "Shop1", "products": [
    {"name": "Olive Oil", "price": 12.12, "country": "Italy"},
    {"name": "Olive Oil", "price": 18.12, "country": "Spain"},
    {"name": "Olive Oil", "price": 15.12, "country": "Greece"},
  ] },
  {"location": "Shop2", "product": [
    {"name": "Sunflower Oil", "price": 3.12, "country": "Germany"},
  ]  } \n]
                  `}</span>
                    </code>
                    <br/>
                    {`Need to be entered as product[name, product[price, product[country.`}
                    <span> </span>Univariate quality checks are successful
                    if <strong>all</strong> values are compliant with the quality rules.
                    For multivariate tests, at least <strong>one</strong> value needs
                    to comply.
                    If a condition and an effect test of a multivariate test instance
                    check attributes of the same list of nested objects, list entries
                    are treated as single objects and are evaluated <strong>separately</strong>.
                  </Typography>
                  <br/>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Lists and dictionaries can be nested at will.
                    Mixed lists (different technical types or objects and values)
                    are not yet fully supported.</Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Streaming</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" gutterBottom>
                  Streaming allows to send a feed split in multiple parts and each part is evaluated as soon as it
                  is sent to the server.
                  </Typography>

                  <Typography sx={{ fontWeight: 'bold' }}>Start Stream</Typography>
                  <Typography variant="body2">Send POST request to url: {`${currentUrl}/start_streaming`}</Typography>
                  <Typography variant="body2">This returns the er_id which
                    needs to be provided when streaming data.
                  </Typography>
                  <div className="markdown">
                    <pre><code className="language-js"><span className="token keyword">
                      {
                      // eslint-disable-next-line max-len
                        `{\n "feed_id": <feed_id>, \n "token": "<token>",\n "reference_label": "<reference_label>",\n "timeout": <optional|60: duration in minutes>\n}
                    `}</span>
                    </code></pre>
                  </div>

                  <Typography sx={{ fontWeight: 'bold' }}>Close Stream</Typography>
                  <Typography variant="body2">Send POST request to url: {`${currentUrl}/close_streaming`}</Typography>
                  <div className="markdown">
                    <pre><code className="language-js"><span className="token keyword">
                      {
                      // eslint-disable-next-line max-len
                        `{\n "feed_id": <feed_id>, \n "token": "<token>",\n "er_id": <feed_exuction_result_id>\n}
                    `}</span>
                    </code></pre>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Returned Data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" gutterBottom>
                    As a response on a pipeline execution request, a detailed report will be returned.
                    It contains the sent data and the execution results.
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>data</Typography>
                  <Typography variant="body2">This returns the original sent data.</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>execution_result_id</Typography>
                  <Typography variant="body2">The assigned Execution result ID.</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>feed_execution_successful & msg</Typography>
                  <Typography variant="body2">Was the pipeline execution successfull or did warnings occur.</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>qual_et_data_annotated</Typography>
                  <Typography variant="body2">A detailed report about failed data objects.
                  Auto converted values will be displayed in the following tests results.</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>Example Response</Typography>
                  <div className="markdown">
                    <pre><code className="language-js"><span className="token keyword">
                      {
                        `                       {
                          "data":
                          [
                            {"price": "-2.32"},
                            {"product_name": "Olive Oil"}
                          ],
                        "execution_result_id": 1,
                        "feed_execution_successful": false,
                        "msg": "Data processed with warnings",
                        "qual_et_data_annotated": [
                          {
                            "data": {
                              "price": "-2.32"
                            },
                            "qual_et_results": {
                              "data_object_id": 1,
                              "execution_success": false,
                              "alerts": [
                                {
                                  "code": "W11",
                                  "field": "price",
                                  "message": "Data Type had to be converted.",
                                  "value": "-2.32",
                                  "type": "warning"
                                },
                                {
                                  "code": "W16",
                                  "field": "price",
                                  "message": "Value must be positive.",
                                  "value": -2.32,
                                  "type": "warning"
                                }
                              ]
                            }
                          },
                        ],
                        "stats": {
                          "amount_data_objects": 2,
                          "amount_data_objects_with_warnings": 1,
                          "amount_data_objects_with_errors": 0,
                          "percentage_of_data_objects_with_warnings": 50.0,
                          "percentage_of_data_objects_with_errors": 0.0,
                          "processing_time_in_seconds": -3599.673422
                        },
                        "warnings": [
                          "amount_data_objects_with_warnings: Value must be 0. Actual value is: 1."
                        ]
                      }
                    `}</span>
                    </code></pre>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Example Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Python" {...a11yProps(0)} />
                      <Tab label="CURL" {...a11yProps(1)} />
                      <Tab label="Postman" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <code style={{whiteSpace: 'pre-line'}}>
                    import requests <br />
                    import json
                      <br /><br />
                      {`message = {`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"feed_id": <feed_id>,`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"token": "<token>",`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"er_id": <optional|feed_exuction_result_id>,`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"data": [{"price": 2.32, "product_name": "Olive Oil"}]`} <br />
                      {`}`} <br />
                    json_message = json.dumps(message)
                      <br />
                      {`headers = {"Content-Type": "application/json"}`}
                      <br />
                      {`url = '${currentUrl}/start_pipeline'`}
                      <br />
                    # Testing usage <br />
                    result = requests.post(url, headers=headers, data=json_message)
                      <br /><br />
                    # Productive usage <br />
                    import urllib3 <br />
                    from urllib3.util import Retry
                      <br /><br />
                    retries = Retry(connect=5, read=2, redirect=5)<br />
                    http = urllib3.PoolManager(retries=retries)<br />
                    response = http.request("POST", url=url, headers=headers, body=json_message)<br />

                    </code>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <code>
                      {`curl -X POST ${currentUrl}/start_pipeline -H 'Content-Type: application/json'
                    -d '{ "feed_id": <feed_id>, "token": "<token>", "er_id": <optional|feed_exuction_result_id>
                    "data": [{"price": 2.32, "product_name": "Olive Oil"}] }'`}
                    </code>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <code>
                      Request URL:
                      <br />
                      POST {`${currentUrl}/start_pipeline`}
                      <br />
                      Headers: Content-Type: application/json
                      <br />
                      Body &gt; raw &gt; JSON
                      <br />
                      {`{`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"feed_id": <feed_id>,`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"token": "<token>",`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"er_id": <optional|feed_exuction_result_id>,`} <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{`"data": [{"price": 2.32, "product_name": "Olive Oil"}]`} <br />
                      {`}`} <br />
                    </code>
                  </TabPanel>
                </AccordionDetails>
              </Accordion>

            </Card>
          </Grid>
        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default Documentation;
