import { Divider, IconButton, ListItem, MenuItem, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {AlertDropdown, QualityTestDropdown} from '../../components/qualityTestDropdown';
import QualityTestParameterInput from '../../components/qualityTestParameterInput';

export const RenderTest = ({
  test, variables, qualityTests,
  updateMultivariateTests,
  updateMultivariateTestParameters,
  removeTest, updateVariable,
  type, index, domainTables,
}) => {


  const [testDescription, setTestDescription] = useState('');
  const [qualityTestParameter, setQualityTestParameter] = useState([]);

  // Setup the variables and those kind of things
  useEffect(() => {
    const qualityTestDefinition = qualityTests.find(item =>
      item.quality_test_id === test.quality_test_id);
    if (qualityTestDefinition !== undefined) {
      setTestDescription(qualityTestDefinition.quality_test_description);
      setQualityTestParameter(qualityTestDefinition.quality_test_parameter);
    } else {
      setTestDescription('');
      setQualityTestParameter([]);
    }
  }, [qualityTests, test.quality_test_id]);

  const selectTest = (testId) => {
    const qualityTest = qualityTests.find(item =>
      item.quality_test_id === testId);
    setTestDescription(qualityTest.quality_test_description);
    updateMultivariateTests(test.quality_test_instance_id, 'quality_test_id', testId);
  };

  const changeAlertType = (alert_type) => {
    updateMultivariateTests(test.quality_test_instance_id, 'alert_type', alert_type);
  };

  const updateTestParameterValues = (value, quality_test_parameter_id) => {
    updateMultivariateTestParameters(
      test.quality_test_instance_id, quality_test_parameter_id, value);
  };

  const selectVariable = (variable_id) => {
    const setVariable = variables.find(variable => variable.variable_id === variable_id);
    updateVariable(
      test.quality_test_instance_id, variable_id,
      setVariable.semantical_type_id, setVariable.technical_type_id
    );
  };

  return (
    <>
      <ListItem
        data-testid={`mv-test-row-${type}-${index}`}
        secondaryAction={
          <Tooltip title="Deactivate">
            <IconButton onClick={() => removeTest(test.quality_test_instance_id)}
              data-testid={`delete-${type}-test-${index}-icon-button`}
              edge="end" color="error" aria-label="delete-test">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <TextField
          data-testid={`outlined-mv-variable-input-${type}-${index}-id`}
          id={`outlined-mv-variable-input-${type}-${index}`}
          sx={{mr: 2}}
          select
          required
          value={test.variable_id}
          label="Please select"
          helperText="Select Feed Variable"
          inputProps={{
            id: `mv-variable-input-${type}-${index}`
          }}
          onChange={
            (event) => selectVariable(event.target.value)
          }
        >
          {variables.map((option) => (
            <MenuItem key={option.variable_id} value={option.variable_id}>
              {option.variable_name}
            </MenuItem>
          ))}
        </TextField>
        <QualityTestDropdown
          qualityTests={qualityTests}
          technical_type_id={test.technical_type_id}
          semantical_type_id={test.semantical_type_id}
          selectedTestId={test.quality_test_id}
          disabled={false}
          setter={selectTest}
          sx={{mr: 2}}
        />
        { type === 'effect' ?
          <AlertDropdown
            selectedAlert={test?.alert_type}
            setter={changeAlertType}
            sx={{mr: 2}}
          /> :
          <></>}
        {qualityTestParameter.map((parameter, i) => (
          <QualityTestParameterInput parameter={parameter}
            testParameterValues={test.test_parameter}
            index={i}
            key={i}
            sx={{mt: 0, mr: 1}}
            onChange={updateTestParameterValues}
            variableTechnicalTypeId={test.technical_type_id}
            domainTables={domainTables}/>
        ))}
        <br />
      </ListItem>
      <ListItem sx={{mt: -2}}>
        {testDescription}
      </ListItem>
      <Divider sx={{mt: 1, mb: 1}} component="li" />
    </>
  );
};
