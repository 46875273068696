import { Button, Card, CardContent,
  CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { copyFeed, deactivateFeed } from '../serverCommunication/serverCommunication';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ArticleIcon from '@mui/icons-material/Article';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import SkeletonTableLoader from './skeletonTableLoader';
import { useTranslation } from 'react-i18next';
import KeyIcon from '@mui/icons-material/Key';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFetch } from '../hooks/useFetch';
import DomainContext from '../contexts/domainContext';
import { ConfirmDeletionDialog } from './confirmDeletionDialog';
import AuthContext from '../contexts/authContext';
import { downloadFile } from '../serverCommunication/downloadFile';

function FeedOverviewCard() {

  const { t } = useTranslation();

  const {feedExecutionTimes} = useContext(DomainContext);
  const {getToken, getUserId} = useContext(AuthContext);


  // Handle the loading server communication
  const {data: feedData, loading} = useFetch('/request_feeds', []);

  const [feeds, setFeeds] = useState([]);

  // Dialog handling
  const [open, setOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [feedToken, setFeedToken] = useState();
  const [feedId, setFeedId] = useState();
  // Checks whether the token has been copied
  const [copied, setCopied] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // As we need to manipulate the feeds we are storing it
  // in a new hook
  useEffect(() => {
    setFeeds(feedData);
  }, [feedData]);

  const handleFeedCopy = (copyFeedId) => {
    copyFeed(getToken(), copyFeedId, setFeeds);
  };

  const handleDownloadVariableConfig = (feed_id) => {
    const url = `/download_variable_config/${feed_id}`;
    // Passing an empty function as there is currently no
    // usage of the loading status
    downloadFile(getToken(), url, () => {});
  };

  return (
    <>
      <ConfirmDeletionDialog
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        element={'Feed'}
        executionFunction={() => {
          deactivateFeed(getToken(), feedId, setFeeds, feeds); setConfirmDeleteOpen(false);
        }} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Feed API Authentication"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please append the feed_id and the feed authentication token when sending a feed to the api.
            Further details can be found in the documentation.
          </DialogContentText>
          <CopyToClipboard onCopy={() => setCopied(true)} text={feedId}>
            <Tooltip
              title={(copied) ? "Copied" : "Click to copy feed_id"}
              placement="bottom"
              onClose={() => setCopied(false)}
            >
              <div className="markdown-body" aria-label="Click to copy the token">
                <pre><code className="language-js"><span className="token keyword">
                  "feed_id": {feedId},</span>
                </code>
                </pre></div>
            </Tooltip>
          </CopyToClipboard>
          <CopyToClipboard onCopy={() => setCopied(true)} text={feedToken}>
            <Tooltip
              title={(copied) ? "Copied" : "Click to copy token"}
              placement="bottom"
              onClose={() => setCopied(false)}
            >
              <div className="markdown-body" aria-label="Click to copy the token">
                <pre><code className="language-js"><span className="token keyword">
                  "token": "{feedToken}"</span>
                </code>
                </pre></div>
            </Tooltip>
          </CopyToClipboard>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader
          title={'Feed Definitions'}
          action={<Link to="/createFeed"><Button variant="contained">New Feed</Button></Link>}
        />
        <CardContent>

          {/* Here comes an overview of all feeds {feeds} */}
          <TableContainer>
            <Table size="small" aria-label="Table with vacation requests">
              <TableHead>
                <TableRow>
                  <TableCell>{("Feed name")}</TableCell>
                  <TableCell>{("Feed description")}</TableCell>
                  <TableCell>{("Frequency")}</TableCell>
                  <TableCell>{("Action")}</TableCell>
                </TableRow>
              </TableHead>
              {(loading) ?
                <SkeletonTableLoader columns={3} rows={4} />: (<TableBody>
                  {feeds.map((row, i) => {
                    return (
                      <TableRow key={row['feed_id']}>
                        <TableCell key={row['feed_id'] + '_' + row['feed_name']}>
                          {row['feed_name']}
                        </TableCell>
                        <TableCell key={row['feed_id'] + '_' + row['feed_description']}>
                          {row['feed_description']}
                        </TableCell>
                        <TableCell key={`${row.feed_id}_${row.execution_time_id}`}>
                          {t(feedExecutionTimes.find(
                            item => item.execution_time_id === row.execution_time_id)?.execution_time_name) }

                        </TableCell>
                        <TableCell key={row['feed_id'] + '_Actions'}>
                          <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="View Feed Authentication Details">
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setFeedToken(row.token);
                                  setFeedId(row.feed_id);
                                }}
                                sx={{ p: 0, pr: 1, pl: 1 }}
                                color="primary">
                                <KeyIcon />
                              </IconButton>
                            </Tooltip>
                            |
                            <Tooltip title="View Documentation">
                              <Link to={`/documentation`}>
                                <IconButton
                                  sx={{ p: 0, pr: 1, pl: 1 }}
                                  color="primary">
                                  <ArticleIcon />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            |
                            <Tooltip title="Edit Feed">
                              <Link to={`/editFeed/${row['feed_id']}`}>
                                <IconButton
                                  sx={{ p: 0, pr: 1, pl: 1 }}
                                  color="secondary">
                                  <EditIcon />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            |
                            <Tooltip title="Edit Variables">
                              <Link to={`/createVariable/${row['feed_id']}`}>
                                <IconButton
                                  sx={{ pr: 1, pl: 1, pt: 0, pb: 0 }}
                                  color="secondary">
                                  <AddTaskIcon />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            |
                            <Tooltip title="Copy Feed">
                              <IconButton
                                onClick={() => {
                                  handleFeedCopy(row.feed_id);
                                }}
                                sx={{ p: 0, pr: 1, pl: 1 }}
                                color="secondary">
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                            |
                            <Tooltip title="Export Feed Variable Config">
                              <IconButton
                                onClick={() => {
                                  handleDownloadVariableConfig(row.feed_id);
                                }}
                                sx={{ p: 0, pr: 1, pl: 1 }}
                                color="secondary">
                                <FileOpenIcon />
                              </IconButton>
                            </Tooltip>
                            |
                            <Tooltip
                              title={row.user_id === getUserId() ?
                                "Deactivate" : "Only the owner can deactivate their feeds"}>
                              <span>
                                <IconButton
                                  sx={{ p: 0, pr: 1, pl: 1 }}
                                  color="error"
                                  disabled={row.user_id !== getUserId()}
                                  // onClick={() => deactivateFeed(getToken(), row['feed_id'], setFeeds, feeds)}
                                  onClick={() => { setFeedId(row.feed_id); setConfirmDeleteOpen(true); }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                )
              }
            </Table>
          </TableContainer>

        </CardContent>
      </Card>
    </>
  );
}

export default FeedOverviewCard;
