import { Divider, IconButton, ListItem, MenuItem, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { SemanticQualityTestDropdown } from './qualityTestDropdown';
import QualityTestParameterInput from './qualityTestParameterInput';

export const RenderFeedTest = ({
  test, feedAttributes, qualityTests,
  updateMultivariateTests,
  updateMultivariateTestParameters,
  removeTest,
  index
}) => {

  const [testDescription, setTestDescription] = useState('');
  const [qualityTestParameter, setQualityTestParameter] = useState([]);
  // Keeping track of the variableTechnical type for the input field

  // Setup the variables and those kind of things
  useEffect(() => {
    const qualityTestDefinition = qualityTests.find(item =>
      item.quality_test_id === test.quality_test_id);
    if (qualityTestDefinition !== undefined) {
      setTestDescription(qualityTestDefinition.quality_test_description);
      setQualityTestParameter(qualityTestDefinition.quality_test_parameter);
    } else {
      setTestDescription('');
      setQualityTestParameter([]);
    }
  }, [qualityTests, test.quality_test_id]);

  const selectTest = (testId) => {
    const qualityTest = qualityTests.find(item =>
      item.quality_test_id === testId);
    setTestDescription(qualityTest.quality_test_description);
    updateMultivariateTests(index, 'quality_test_id', testId);
  };

  const updateTestParameterValues = (value, quality_test_parameter_id) => {
    updateMultivariateTestParameters(
      index, quality_test_parameter_id, value);
  };

  const selectVariable = (feed_test_attribute_id) => {
    updateMultivariateTests(index, 'feed_test_attribute_id', feed_test_attribute_id);
    updateMultivariateTests(index, 'semantical_type_id', 3);
    updateMultivariateTests(index, 'technical_type_id', 3);
  };

  const getAttributeType = () => {
    return feedAttributes.find(
      item => item.feed_test_attribute_id === test.feed_test_attribute_id)?.technical_type_name;
  };

  return (
    <>
      <ListItem
        data-testid={`feed-test-${index}`}
        secondaryAction={
          <Tooltip title="Deactivate">
            <IconButton onClick={() => removeTest(index)}
              data-testid={`delete-feed-test-${index}-icon-button`}
              edge="end" color="error" aria-label="delete-test">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <TextField
          data-testid={`select-feed-test-attribute-${index}`}
          id="outlined-select-feed-test"
          sx={{mr: 2}}
          select
          inputProps={{
            id: "feed-test-attribute-input"
          }}
          required
          value={test.feed_test_attribute_id}
          label="Please select"
          helperText="Select Feed Variable"
          onChange={
            (event) => selectVariable(event.target.value)
          }
        >
          {feedAttributes.map((option) => (
            <MenuItem key={option.feed_test_attribute_id} value={option.feed_test_attribute_id}>
              {option.feed_test_attribute}
            </MenuItem>
          ))}
        </TextField>
        <SemanticQualityTestDropdown
          qualityTests={qualityTests}
          technical_type_id={test.technical_type_id}
          semantical_type_id={test.semantical_type_id}
          selectedTestId={test.quality_test_id}
          disabled={false}
          setter={selectTest}
          sx={{mr: 2}}
        />
        {qualityTestParameter.map((parameter, i) => (
          <QualityTestParameterInput
            parameter={parameter}
            testParameterValues={test.test_parameter}
            index={i}
            key={i}
            sx={{mt: 0, mr: 1}}
            onChange={updateTestParameterValues}
            variableTechnicalType={getAttributeType()} />
        ))}
        <br />
      </ListItem>
      <ListItem sx={{mt: -2}}>
        {testDescription}
      </ListItem>
      <Divider sx={{mt: 1, mb: 1}} component="li" />
    </>
  );
};
