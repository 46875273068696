import React, { useContext } from 'react';
import { Button, Card, CardActions, Divider, List, ListItemButton, ListItemIcon,
  ListItemText} from '@mui/material';
import { useTheme } from '@emotion/react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DomainContext from '../../contexts/domainContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MaterialTable from '@material-table/core';


export const FeedVariableList = (
  {variables, handleVariableChange,
    selectVariable, copyVariable, newVariable, feedId,
    selectedIndex, handleFilterChange, materialTableFilters
  }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const {semanticalTypeDomain, technicalTypeDomain} = useContext(DomainContext);

  const sematicalNames = Object.fromEntries(
    semanticalTypeDomain.map(el => [el.semantical_type_id, el.semantical_type_name])
  );
  const technicalNames = Object.fromEntries(
    technicalTypeDomain.map(el => [el.technical_type_id, el.technical_type_name])
  );

  return (
    <Card>
      <List component="nav" aria-label="variable-list">
        <ListItemButton
          key={`button-variable`}
          selected={selectedIndex === 0}
          onClick={() => {
            handleVariableChange('newVariable');
          }}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={'New Variable'}
          />
        </ListItemButton>
        <Divider />

        <MaterialTable
          title={t('Variable list')}
          columns={[
            {title: 'Name',
              field: 'variable_name',
              defaultFilter: materialTableFilters?.variable_name
            },
            {title: 'Semantic type',
              field: 'semantical_type_id',
              lookup: sematicalNames,
              defaultFilter: materialTableFilters?.semantical_type_id
            },
            {title: 'Technical type',
              field: 'technical_type_id',
              lookup: technicalNames,
              defaultFilter: materialTableFilters?.technical_type_id
            },
          ]}
          data={variables}
          actions={[
            {
              icon: () => <ContentCopyIcon />,
              tooltip: 'Copy',
              onClick: (event, data) => copyVariable(
                Object.fromEntries(Object.entries(data).filter(([k, v]) => k !== "tableData"))
              )
            },
          ]}
          onRowClick={(event, rowData) => {
            handleVariableChange(
              'updateVariable',
              Object.fromEntries(Object.entries(rowData).filter(([k, v]) => k !== "tableData")),
              rowData.variable_id
            );
          }}
          onFilterChange={(filters) => {
            handleFilterChange(filters);
          }}
          options={{
            filtering: true,
            cellStyle: { padding: '0.3em'},
            headerStyle: { padding: '0.3em'},
            pageSize: 10,
            pageSizeOptions: [10, 20, 50],
            actionsColumnIndex: -1,
            rowStyle: (rowData) => ({
              backgroundColor: selectedIndex === rowData.variable_id ?
                theme.palette.action.selected : theme.palette.background.paper,
            }),
          }}
        />
      </List>
      <CardActions>
        <Link to={`/editFeed/${feedId}`}>
          <Button size="small" color='secondary'>{t('Back')}</Button>
        </Link>
        <Link to={`/multivariateTests/${feedId}`}>
          <Button size="small">Multivariate Tests</Button>
        </Link>
      </CardActions>
    </Card>
  );
};
